const user_data = [
    {
        "description": "HEAD BRANCH M AAPKA SWAGAT H FARIDABAD GHAZIABAD GALI DISAWAR ALL GAME LEAK REPORT AAP JITNA CHAHOGE UTNA KAMAOGE. KAMANE KE LIYE TURANT WHATSAPP MESSAGE KAREN.",
        "id": 38,
        "mobile": " 8168312236",
        "name": "RAM BHAI KHAIWAL",
        "post": "HEAD OF COMPANY",
        "status1": 1,
        "web_type": "1"
    }, {
        "description": "HEAD BRANCH M AAPKA SWAGAT H FARIDABAD GHAZIABAD GALI DISAWAR ALL GAME LEAK REPORT AAP JITNA CHAHOGE UTNA KAMAOGE. KAMANE KE LIYE TURANT WHATSAPP MESSAGE KAREN.",
        "id": 38,
        "mobile": " 9034114414",
        "name": "RAM Online khaiwal",
        "post": "HEAD OF COMPANY",
        "status1": 1,
        "web_type": "1"
    }, {
        "description": "HEAD BRANCH M AAPKA SWAGAT H FARIDABAD GHAZIABAD GALI DISAWAR ALL GAME LEAK REPORT AAP JITNA CHAHOGE UTNA KAMAOGE. KAMANE KE LIYE TURANT WHATSAPP MESSAGE KAREN.",
        "id": 38,
        "mobile": " 7015808109",
        "name": "भरोसेबंद खाईवाल ",
        "post": "HEAD OF COMPANY",
        "status1": 1,
        "web_type": "1"
    }
]
export default user_data;